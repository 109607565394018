<template>
  <section id="signup">
    <v-card class="v-card--auth pa-5" outlined max-width="500">
      <v-container pa-0 grid-list-xl>
        <h1 class="headline font-weight-regular primary--text">
          Opdater kodeord
        </h1>
        <v-form ref="form" v-model="form" class="mb-5" @keyup.native.enter="doChangePassword"
          style="padding: 1rem 1rem 0rem 1rem">
          <v-container pa-0>
            <v-row>
              <v-text-field autofocus v-model="email" @input="setAuthEmail"
                :rules="[rules.required('Indtast email adresse')]" label="Email" name="email" persistent-hint />
            </v-row>
            <v-row>
              <v-text-field v-model="password" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required('Indtast kodeord')]" :type="show ? 'text' : 'password'" label="Nyt kodeord"
                @click:append="show = !show" />
            </v-row>
            <v-row>
              <v-text-field v-model="confirmPassword" :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required('Gentag kodeord'), rules.confirm]" :type="showConfirm ? 'text' : 'password'"
                label="Gentag kodeord" @click:append="showConfirm = !showConfirm" />
            </v-row>
            <v-row>
              <v-text-field v-model="confirmationCode" :rules="[rules.required('Bekræftelseskode')]"
                label="Bekræftelseskode" @click:append="show = !show" />
            </v-row>
            <v-row>
              <v-btn color="primary" @click="doChangePassword" v-bind:loading="isLoading">Send</v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-container>
    </v-card>
  </section>
</template>

<script>
// Utilities
import { mapActions, mapMutations } from "vuex";

export default {
  data() {
    const data = {
      isLoading: false,
      form: false,
      email: this.$route.query.email || this.$store.state.authEmail,
      password: undefined,
      confirmPassword: undefined,
      confirmationCode: this.$route.query.confirmation_code,
      rules: {
        required: (msg) => (v) => !!v || msg,
        confirm: (v) => (v ? v === this.password : "Kodeord matcher ikke"),
      },
      show: false,
      showConfirm: false,
    };
    return data;
  },
  methods: {
    ...mapActions("cognito", ["changePassword"]),
    ...mapMutations(["setSnackbar", "setAuthEmail"]),

    doChangePassword() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.isLoading = true;

      this.changePassword({
        username: this.email,
        newPassword: this.password,
        code: this.confirmationCode,
      })
        .then(() => {
          this.setSnackbar({
            type: "success",
            msg: "Kodeord ændret",
          });
          this.$router.push({ name: "profile" });
        })
        .catch((err) => {
          this.setSnackbar({
            type: "error",
            msg: err.message,
            timeout: 10000,
          });
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
